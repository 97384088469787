<template>
    <div class="login">
       <div class="login__content">
           <div class="content__form">
               <h1>Iniciar sesión</h1>
               <label for="email">Email:</label>
               <input type="email" placeholder="Email"  name="email" v-model="user.mail" required autofocus>
               <label for="password">Contraseña:</label>
               <input type="password" placeholder="Contraseña" name="password" v-model="user.password" required autofocus>
               <button type="submit" 
               @click="logIn()"
               autofocus>
                    <span v-show="!loading">Iniciar sesión</span>
                    <loader v-show="loading"></loader>
               </button>
           </div>
           <div class="content__logo" id="logos">
               <img src="https://firebasestorage.googleapis.com/v0/b/di-medical-del-sur.appspot.com/o/static%2FlogoCorporativo.png?alt=media&token=ca32a756-7656-4259-b5b7-921c11a0a3e8" alt="Logo Di-medical corporativo" srcset="">
           </div>
       </div>

    <div class="background">
        <div class="hexagon"></div>
        <div class="hexagon"></div>
        <div class="hexagon"></div>
        <div class="hexagon"></div>
        <div class="hexagon"></div>
    </div>


    </div>
</template>

<script>

const loader = () => import(/* webpackChunkName: "Loader" */"../../components/loaders/loaderRegister.vue");

export default {
    name:"Login",
    
    components:{
        loader
    },

    data(){
        return{
            user:{
                mail:"",
                password: ""
            },
            loading: false      
        }
    },

    mounted(){
        // windows
    },


    methods:{
        async logIn(){
            this.loading = true;
           try{
                await this.$store.dispatch("user/doLogin", {
                    email: this.user.mail,
                    password: this.user.password
                })
                this.$toast.success("Bienvenido");
                this.redirect();
            } catch(e){
                this.$toast.error("Contraseña o correo incorrectos");
            } finally{
                this.loading = false;
            }
        },

        redirect(){
            this.$router.push({ name: "Dashboard" });
        },
    }
}
</script>